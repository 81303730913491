.mdc-tab__text-label,
.mat-mdc-tab-link {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 1px;
  justify-content: center;
  text-align: center;

  &.mdc-tab--active {
    &:not(.mat-mdc-tab-disabled),
    &.cdk-keyboard-focused:not(.mat-mdc-tab-disabled) {
      color: var(--primary-color);
      opacity: 1;
    }
  }
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color);
}

$header-border: 1px solid rgba(0 0 0 / 12%);

.mat-mdc-tab-header {
  border-bottom: $header-border;
}

 .mat-mdc-tab-body {
  background-color: #f8f8f8;
}

.mat-mdc-tab-group .mat-mdc-tab-body-wrapper{
  padding-top: var(--cell-padding);
}
