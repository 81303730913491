@function px-to-rem($val) {
  $result: calc($val / 16);

  @return $result;
}
$ectr-custom-primary500: #0072ab;

$success: green;
$dark-green: #007048;
$warning: #f3c834;
$error: red;
$accepted: #189130;
$rejected: #b52f32;
$pending: #b59305;
$black-60: rgba(0 0 0 / 60%);
$white: #fff;

$box-background-color: $white;
$not-disabled-background: rgba(0 0 0 / 5%);

$table-solid-border: 1px solid rgba(0 0 0 / 6%);
$input-cell-text-color: rgba(0 0 0 / 87%);

// layout
$layout-top-bar-height: 64px;
$layout-sidenav-width: 300px;

$overview-toolbar-height: 48px;
$overview-content-padding: 80px;
$overview-content-filter-min-height: 235px; // filters headers and pagination

$table-header-background-color: #fbfcf0;
$table-row-even-background-color: #f7f7f7;
$table-border-color: #ddd;

$calendarization-table-font-size: 12px;

.warning-icon {
  color: $warning;
}
